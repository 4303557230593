<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col md:w-1/6 mt-4">
            <label class="text-sm">Date de début</label>
            <flat-pickr v-model.lazy="DateDebut" :config="configdateTimePicker" class="w-full" name="DateDebut" />
          </div>
          <div class="vx-col md:w-1/6 mt-4">
            <label class="text-sm">Date de fin</label>
            <flat-pickr v-model.lazy="DateFin" :config="configdateTimePicker" class="w-full" name="DateFin" />
          </div>
          <div class="vx-col md:w-1/6 mt-4">
            <vs-select v-if="principale" label="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="idOfficine">
              <vs-select-item  class="w-full" value="Touts" text="Touts" />
              <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
            </vs-select>
          </div>
          <div class="vx-col md:w-1/6 mt-10">
            <vs-button ref="loadableButton" id="button-with-loading" class="w-full vs-con-loading__container" color="primary" icon-pack="feather" icon="icon-search" @click="getCA()">Valider</vs-button>
          </div>
          <div class="vx-col md:w-1/6 mt-10">
            <vs-button id="pdf-button-with-loading" class="w-full vs-con-loading__container" color="primary" icon-pack="feather" icon="icon-file" @click="printCaToPdf()">Impression</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
    <div  class="vx-col w-full mt-5">
      <vx-card id="corps-proforma">
        <!-- PDF Generator -->

        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="`CA_${DateDebut}_${DateFin}`"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="landscape"
          pdf-content-width="95%"

          ref="html2Pdf"
        >
          <section class="p-10 pl-20" slot="pdf-content">
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="vx-row">
                  <div class="vx-col w-1/3 mt-base">

                  </div>
                  <div class="vx-col w-1/3 mt-base text-center">
                    <div class="titre_etat_regl">
                      <h4>CHIFFRE D'AFFAIRES</h4>
                    </div>
                  </div>
                  <div class="vx-col w-1/3 mt-base text-right">
                    <!-- {{proforma.createdAt | moment("calendar", "July 10 2011") }} -->
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <div class="vx-col w-full text-center">
                    <h4>Période du {{DateDebut | moment("calendar", "July 10 2011") }} au {{DateFin | moment("calendar", "July 10 2011") }}</h4>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <!-- {{reglements_client}} -->
                  <table class="w-full customtable" cellpadding="0" cellspacing="0">
                    <thead>
                      <tr>
                        <th class="customth" colspan="1"  >Officine</th>
                        <th class="customth text-center" colspan="1" >Nbre de vente</th>
                        <th class="customth text-center" colspan="1" >Prix moyen de vente</th>
                        <th class="customth text-center" colspan="1" >Client</th>
                        <th class="customth text-center" colspan="1" >Comptoire</th>
                        <th class="customth text-center" colspan="1" >Assurance</th>
                        <th class="customth text-center" colspan="1" >CA</th>
                      </tr>
                    </thead>
                    <!-- v-if="br.length >0" -->
                    <tbody>
                      <tr :key="indextr" v-for="(tr, indextr) in ca">
                        <td class="customtd"  colspan="1">{{officineName(tr.idOfficine)}}</td>
                        <td class="customtd text-right"  colspan="1">{{tr.nbre_vente}}</td>
                        <td class="customtd text-right"  colspan="1">{{moneyFormatter(+tr.prix_moyen)}}</td>
                        <td class="customtd text-right"  colspan="1">{{moneyFormatter(tr.client)}}</td>
                        <td class="customtd text-right"  colspan="1">{{moneyFormatter(tr.comptoire)}}</td>
                        <td class="customtd text-right"  colspan="1">{{moneyFormatter(tr.assurance)}}</td>
                        <td class="customtd text-right"  colspan="1">{{moneyFormatter(tr.ca)}}</td>
                      </tr>
                    </tbody>

                    <tfoot>
                      <tr>
                        <th class="customth text-right" colspan="1">Total</th>
                        <th class="customth text-right" colspan="1">{{totalNbreVente}}</th>
                        <th class="customth text-right" colspan="1">{{moneyFormatter(+totalPrixMoyen)}}</th>
                        <th class="customth text-right" colspan="1">{{moneyFormatter(caClient)}}</th>
                        <th class="customth text-right" colspan="1">{{moneyFormatter(totalComptoire)}}</th>
                        <th class="customth text-right" colspan="1">{{moneyFormatter(caAssurance)}}</th>
                        <th class="customth text-right" colspan="1">{{moneyFormatter(totalCa)}}</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </vue-html2pdf>

        <!-- PDF Generator end -->
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="vx-row">
              <div class="vx-col w-1/3 mt-base">

              </div>
              <div class="vx-col w-1/3 mt-base text-center">
                <div class="titre_etat_regl">
                  <h1>CHIFFRE D'AFFAIRES</h1>
                </div>
              </div>
              <div class="vx-col w-1/3 mt-base text-right">
                <!-- {{proforma.createdAt | moment("calendar", "July 10 2011") }} -->
              </div>
            </div>
            <div class="vx-row mt-5">
              <div class="vx-col w-full text-center">
                <h4>Période du {{DateDebut | moment("calendar", "July 10 2011") }} au {{DateFin | moment("calendar", "July 10 2011") }}</h4>
              </div>
            </div>
            <div class="vx-row mt-5">
              <!-- {{reglements_client}} -->
              <table class="w-full customtable" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th class="customth" colspan="1"  >Officine</th>
                    <th class="customth text-center" colspan="1" >Nbre de vente</th>
                    <th class="customth text-center" colspan="1" >Prix moyen de vente</th>
                    <th class="customth text-center" colspan="1" >Client</th>
                    <th class="customth text-center" colspan="1" >Comptoire</th>
                    <th class="customth text-center" colspan="1" >Assurance</th>
                    <th class="customth text-center" colspan="1" >CA</th>
                  </tr>
                </thead>
                <!-- v-if="br.length >0" -->
                <tbody>
                  <tr :key="indextr" v-for="(tr, indextr) in ca">
                    <td class="customtd"  colspan="1">{{officineName(tr.idOfficine)}}</td>
                    <td class="customtd text-right"  colspan="1">{{tr.nbre_vente}}</td>
                    <td class="customtd text-right"  colspan="1">{{moneyFormatter(+tr.prix_moyen)}}</td>
                    <td class="customtd text-right"  colspan="1">{{moneyFormatter(tr.client)}}</td>
                    <td class="customtd text-right"  colspan="1">{{moneyFormatter(tr.comptoire)}}</td>
                    <td class="customtd text-right"  colspan="1">{{moneyFormatter(tr.assurance)}}</td>
                    <td class="customtd text-right"  colspan="1">{{moneyFormatter(tr.ca)}}</td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <th class="customth text-right" colspan="1">Total</th>
                    <th class="customth text-right" colspan="1">{{totalNbreVente}}</th>
                    <th class="customth text-right" colspan="1">{{moneyFormatter(+totalPrixMoyen)}}</th>
                    <th class="customth text-right" colspan="1">{{moneyFormatter(caClient)}}</th>
                    <th class="customth text-right" colspan="1">{{moneyFormatter(totalComptoire)}}</th>
                    <th class="customth text-right" colspan="1">{{moneyFormatter(caAssurance)}}</th>
                    <th class="customth text-right" colspan="1">{{moneyFormatter(totalCa)}}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
/* lazy loading Vue components */
const flatPickr = () => import('vue-flatpickr-component')
import 'flatpickr/dist/flatpickr.css'
//import {French as FrenchLocale} from 'flatpickr/dist/l10n/fr.js'
const VueHtml2pdf = () => import('vue-html2pdf')
export default {
  components: {
    flatPickr,
    VueHtml2pdf
  },
  data () {
    return {
      activeUserInfos: null,
      DateDebut: null,
      DateFin: null,
      idOfficine: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null,
      filename: '',
      configdateTimePicker: { dateFormat: 'd F Y' }
      // configdateTimePicker: { locale: FrenchLocale, dateFormat: 'd F Y' }
    }
  },
  computed: {
    officines () {
      return this.$store.state.officine.officines
    },
    ca () {
      return this.$store.state.etat.ca
    },
    principale () {
      return this.$store.getters['officine/officineIsPrincipale'](JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null)
    },
    totalNbreVente () {
      const ca = this.$store.state.etat.ca
      const nbre = ca.reduce((acc, item) => {
        return acc + (item.nbre_vente ? item.nbre_vente : 0)
      }, 0)
      return nbre
    },

    totalPrixMoyen () {
      const ca = this.$store.state.etat.ca
      const nbre = ca.reduce((acc, item) => {
        return acc + (item.nbre_vente ? item.nbre_vente : 0)
      }, 0)

      const TotalCa = ca.reduce((acc, item) => {
        return acc + (item.ca ? item.ca : 0)
      }, 0)

      const prix_moyen = nbre > 0 ? (TotalCa / nbre).toFixed(0) : 0

      return prix_moyen
    },
    caClient () {
      const ca = this.$store.state.etat.ca
      const Totalclient = ca.reduce((acc, item) => {
        return acc + (item.client ? item.client : 0)
      }, 0)
      return Totalclient
    },
    totalComptoire () {
      const ca = this.$store.state.etat.ca
      const Total = ca.reduce((acc, item) => {
        return acc + (item.comptoire ? item.comptoire : 0)
      }, 0)
      return Total
    },
    caAssurance () {
      const ca = this.$store.state.etat.ca
      const TotalAssurance = ca.reduce((acc, item) => {
        return acc + (item.assurance ? item.assurance : 0)
      }, 0)
      return TotalAssurance
    },
    totalCa () {
      const ca = this.$store.state.etat.ca
      const TotalCa = ca.reduce((acc, item) => {
        return acc + (item.ca ? item.ca : 0)
      }, 0)
      return TotalCa
    }
  },
  methods: {
    async printCaToPdf () {

      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#pdf-button-with-loading',
        type: 'sound',
        scale: 0.45
      })

      await this.$refs.html2Pdf.generatePdf()

      this.$vs.loading.close('#pdf-button-with-loading > .con-vs-loading')

    },
    moneyFormatter (montant) {
    // Utilisation de la fonction locale pour formater le nombre en monnaie
      const formattedMontant = montant.toLocaleString('fr-FR', { minimumFractionDigits: 0 })

      // Remplacement de la virgule par l'espace comme séparateur de milliers
      return formattedMontant.replace('.', ' ')
    },
    officineName (id) {
      const officine = this.$store.state.officine.officines.find((o) => o._id === id)
      return officine.NomOffic
    },
    getCA () {
      console.log(this.DateDebut)
      const payload = {
        DateDebut: new Date(this.DateDebut).toISOString().split('T')[0],
        DateFin:  new Date(this.DateFin).toISOString().split('T')[0],
        idOfficine: this.idOfficine
      }
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#button-with-loading',
        type: 'sound',
        scale: 0.45
      })

      this.$store.dispatch('etat/getCA', payload)
        .then(() => {
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        })
        .finally(() => {
        })

    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created () {
    this.getOfficines()
  }
}
</script>
<style scoped>
.not-data-table{
  display: none;
}

/* table {
  border:   1px solid black;
} */
/* th, td {
  border: 1px solid black;
  padding: 4px;
} */

.customtable, .customth, .customtd {
  border: 0.5px solid black;
  border-collapse: collapse;
  /* font-family: 'Times New Roman'; */
  font-size: 12px;
}

.customtd {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

.titre_etat_regl{
  border: 1px solid;
  background-color: #CFCFCF;
}
</style>
